import {
  GL_COLORS,
  GLDateRangeType,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
} from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { useExtractURLSearch } from "../../../hooks/useExtractURLSearch";
import { useDetailsDeviceStore } from "../store/details-device-store";
import { useGetDailyReadings } from "./Graphs/useGetDailyReadings";

export const useModalDeviceDetails = () => {
  const { state: detailsDeviceState, actions: detailsDeviceActions } =
    useDetailsDeviceStore();

  const { getDailyReadings } = useGetDailyReadings();
  const { setParams } = useExtractURLSearch()

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    rootOptions: {
      width: !isMobile ? "fit-content" : "100%",
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? "transparent" : "inherit",
        alignItems: isMobile ? "flex-end" : "initial",
      },
    },
    inputOptions: {
      width: "fit-content",
    },
    boxOptions: {
      position: "bottom-right",
      style: {
        boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
      },
    },
    currentRelativeTime: detailsDeviceState.currentRelativeTime,
    currentDateRange: detailsDeviceState.currentDateRange,

    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      detailsDeviceActions.setCurrentRelativeTime(option);
    },

    setCurrentDateRange: (range?: GLDateRangeType) => {
      detailsDeviceActions.setCurrentDateRange(range);
    },

    onRangeChange: async (range?: GLDateRangeType) => {
      if (!detailsDeviceState.device) return;

      const { device_id } = detailsDeviceState.device;
      if (!range?.from || !range?.to || !device_id) return;

      detailsDeviceActions.setRange(range);
      detailsDeviceActions.setIsLoading(true);

      await Promise.all([
        getDailyReadings({ device_id, range }),
        // getDowReadings({ device_id, range }),
      ]);

      detailsDeviceActions.setIsLoading(false);
    },
  };

  function onClose() {
    detailsDeviceActions.resetAll();

    setParams({
      q: ""
    })
  }

  return {
    isOpen: detailsDeviceState.modalIsOpen,
    detailsDeviceState,
    relativeFilterTimeOptions,
    onClose,
  };
};
