import {
  GL_BELL,
  GL_COLORS,
  GL_DOC_CSV,
  GL_GLStation,
  GL_POWER_BUTTON,
  GLOnboardingModal,
  GLOnboardingModalList,
  i18n,
  ThemeChanger,
} from "@group-link-one/grouplink-components";
import { useEffect } from "react";

import { useFireStore } from "../Context/FireStoreProvider";
import { IsMaintenance } from "../Context/FlagsProvider";
import { ImpersonateModal } from "../Pages/Impersonate/ImpersonateModal";
import { AuthRoutes } from "./auth.routes";
import { AuthenticatedRoutes } from "./authenticated.routes";

const listOnboarding: GLOnboardingModalList[] = [
  {
    title: i18n.t("onboardingModal.reports.one.title"),
    description: i18n.t("onboardingModal.reports.one.description"),
    icon: <GL_DOC_CSV size={45} fill={GL_COLORS.ACCENT_COLOR} />,
  },
  {
    title: i18n.t("onboardingModal.groups.one.title"),
    description: i18n.t("onboardingModal.groups.one.description"),
    icon: <GL_GLStation size={45} fill={GL_COLORS.SUCCESS} />,
  },
  {
    title: i18n.t("onboardingModal.notification.one.title"),
    description: i18n.t("onboardingModal.notification.one.description"),
    icon: <GL_BELL size={45} fill={GL_COLORS.PURPLE} />,
  },
  {
    title: i18n.t("onboardingModal.devices.one.title"),
    description: i18n.t("onboardingModal.devices.one.description"),
    icon: <GL_POWER_BUTTON size={45} fill={GL_COLORS.WARNING} />,
  },
];

export const Routes = () => {
  const {
    customMessageOptions,
    hasToShowOnboard,
    hasToShowWelcome,
    hasToShowCustomMessage,
    updateUserOnboard,
  } = useFireStore();

  useEffect(() => {
    ThemeChanger({
      light: "#F6F8F9",
      dark: "#313337",
    });
  }, []);

  return (
    <>
      <IsMaintenance>
        <AuthRoutes />
        <AuthenticatedRoutes />
        <ImpersonateModal />
      </IsMaintenance>

      {hasToShowOnboard && !hasToShowWelcome && !hasToShowCustomMessage && (
        <GLOnboardingModal
          list={listOnboarding}
          type="new-version"
          onClick={() => updateUserOnboard("onboard")}
        />
      )}

      {hasToShowWelcome && !hasToShowOnboard && !hasToShowCustomMessage && (
        <GLOnboardingModal
          list={listOnboarding}
          type="welcome"
          onClick={() => updateUserOnboard("welcome")}
        />
      )}

      {hasToShowCustomMessage && !hasToShowWelcome && !hasToShowOnboard && (
        <GLOnboardingModal
          list={customMessageOptions?.options}
          type="custom"
          customTitle={customMessageOptions?.title}
          customDescription={customMessageOptions?.description}
          onClick={() => updateUserOnboard("custom-message")}
        />
      )}
    </>
  );
};
