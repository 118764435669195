import {
  GL_COLORS,
  GLDateRangeType,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { useHealthCheckStore } from "../../store/health-check-store";

export const useRightFilters = () => {
  const { t } = useI18n();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();

  function onClickFilter() {
    healthCheckActions.setModalFilterIsOpen(true);
  }

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    rootOptions: {
      width: !isMobile ? "fit-content" : "100%",
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? "transparent" : "inherit",
        alignItems: isMobile ? "flex-end" : "initial",
      },
    },
    inputOptions: {
      width: "fit-content",
    },
    boxOptions: {
      position: "bottom-right",
      style: {
        boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
      },
    },
    currentRelativeTime: healthCheckState.currentRelativeTime,
    currentDateRange: healthCheckState.currentDateRange,
    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      healthCheckActions.setCurrentRelativeTime(option);
    },
    setCurrentDateRange: (range?: GLDateRangeType) => {
      healthCheckActions.setCurrentDateRange(range);
    },
    onRangeChange: async (range?: GLDateRangeType) => {
      if (!range) return;
      healthCheckActions.setRange(range);

      // onBoundsChanged();
    },
  };

  return {
    healthCheckState,
    healthCheckActions,
    relativeFilterTimeOptions,
    t,
    onClickFilter,
  };
};
