import { GLDeviceList } from "@group-link-one/grouplink-components";

import { DeviceListContent } from "./Content/DeviceListContent";
import { ModalDecomission } from "./ModalDecomission/ModalDecomission";
import { ModalDeviceDetails } from "./ModalDeviceDetails/ModalDeviceDetails";
import { ModalEditDevice } from "./ModalEditDevice/ModalEditDevice";
import { useDeviceListBody } from "./useDeviceListBody";

export function DeviceListBody() {
  const {
    requests,
    deviceListStateGL,
    userGroupsFormatted,
    bigNumbers,
    onSelectUseCase,
    onSelectUserGroup,
  } = useDeviceListBody();

  return (
    <GLDeviceList
      useCaseOptions={deviceListStateGL.useCaseOptionsAvailables}
      userGroups={userGroupsFormatted}
      allDevicesOptions={{
        deviceListContent: <DeviceListContent />,
      }}
      bigNumbersOptions={{ bigNumbers }}
      modalDetails={<ModalDeviceDetails />}
      modalRegister={<ModalEditDevice />}
      modalDecommission={<ModalDecomission />}
      hasPermissionToShowBigNumbers={requests.lastReading}
      isOnTopHeaderContent={!requests.lastReading}
      onSelectUseCase={onSelectUseCase}
      onSelectUserGroup={onSelectUserGroup}
    />
  );
}
