import {
  GLBarChartDataProps,
  GLDateRangeType,
} from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";
import { addDays, differenceInDays, format } from "date-fns";

import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import { GetDevicesActivatedDailyReadingsRow } from "../../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../../store/details-device-store";

interface GetDailyReadingsParams {
  range: GLDateRangeType;
  device_id: number;
}

export const useGetDailyReadings = () => {
  const queryClient = useQueryClient();

  const { state: deviceDetailsState, actions: deviceDetailsActions } =
    useDetailsDeviceStore();
  const { getDevicesActivatedDailyReadings } = useDeviceListService();

  async function getDailyReadings({
    device_id,
    range,
  }: GetDailyReadingsParams) {
    try {
      deviceDetailsActions.setIsLoading(true);
      if (!range.from || !range.to) return;

      const response = await queryClient.fetchQuery({
        queryKey: [
          "deviceList",
          "getDevicesActivatedDailyReadings",
          device_id,
          range.from,
          range.to,
        ],
        queryFn: async () => {
          return await getDevicesActivatedDailyReadings({
            device_id: Number(device_id),
            since: format(new Date(range.from!), "yyyy-MM-dd"),
            until: format(new Date(range.to!), "yyyy-MM-dd"),
            object_readings: true,
          });
        },
        staleTime: 1000 * 60 * 5,
      });

      const channelZero = response?.rows.filter(
        (reading) => reading.channel === 0
      );

      const channelOne = response?.rows.filter(
        (reading) => reading.channel === 1
      );

      deviceDetailsActions.setChannelZero(channelZero);
      deviceDetailsActions.setChannelOne(channelOne);

      deviceDetailsState.channelActive === 0
        ? deviceDetailsActions.setCurrentData(channelZero)
        : deviceDetailsActions.setCurrentData(channelOne);

      return {
        response,
        channelZero,
        channelOne,
      };
    } catch (error) {
      console.error(error);
    } finally {
      deviceDetailsActions.setIsLoading(false);
    }
  }

  function getAverage(data: GLBarChartDataProps[]) {
    const values = data.map((item) => item.x || 0).filter((item) => item > 0);
    const average = values.reduce((a, b) => a + b, 0) / values.length;

    return Number(average.toFixed(2));
  }

  function formatReadings(
    readings: GetDevicesActivatedDailyReadingsRow[] | undefined,
    from?: Date,
    to?: Date
  ): {
    readingsFormatted: GetDevicesActivatedDailyReadingsRow[];
    arrayDate: GLBarChartDataProps[];
    countAllDays: number;
  } {

    if (
      !deviceDetailsState.range.to ||
      !deviceDetailsState.range.from ||
      !readings
    )
      return { readingsFormatted: [], arrayDate: [], countAllDays: 0 };

    const countAllDays = differenceInDays(
      to || deviceDetailsState.range.to,
      from || deviceDetailsState.range.from
    );

    const arrayDate: GLBarChartDataProps[] = Array.from(
      { length: countAllDays + 1 },
      (_, index) => {
        return {
          x: 0,
          label: format(
            addDays(new Date(from || deviceDetailsState.range.from!), index),
            "MM/dd/yyyy"
          ),
          unit: deviceDetailsState.device?.channels[0].unit,
        };
      }
    );

    const readingsFormatted: GetDevicesActivatedDailyReadingsRow[] =
      arrayDate.map((item) => {
        const findReadingByDate = readings?.find(
          (reading) =>
            format(new Date(reading.time), "MM/dd/yyyy") === item.label
        );

        return {
          channel:
            findReadingByDate?.channel || deviceDetailsState.channelActive || 0,
          // reading: getPreviousReading(
          // index,
          // readings || [],
          // arrayDate
          // ) || 0,
          reading: findReadingByDate?.reading || 0,
          time: item.label,
        };
      });

    return { readingsFormatted, arrayDate, countAllDays };
  }

  function getPreviousReading(
    arrayDateIndex: number,
    readingsData: GetDevicesActivatedDailyReadingsRow[],
    arrayDate: GLBarChartDataProps[]
  ) {
    let arrayDateSplited;

    if (arrayDateIndex === 0) {
      arrayDateSplited = arrayDate.slice(0, arrayDateIndex + 1).reverse();
    } else {
      arrayDateSplited = arrayDate.slice(0, arrayDateIndex + 1).reverse();
    }

    const arrayDateWithIntersectionReadings = arrayDateSplited.map((item) => {
      const hasDateIntersection = readingsData.find(
        (dailyReadingData) =>
          format(new Date(dailyReadingData.time), "MM/dd/yyyy") === item.label
      );

      if (hasDateIntersection) {
        return {
          label: format(new Date(item.label), "MM/dd/yyyy"),
          x: hasDateIntersection.reading,
        };
      } else {
        return {
          label: format(new Date(item.label), "MM/dd/yyyy"),
          x: 0,
        };
      }
    });

    const firstReadingWithValueBeforeIndex =
      arrayDateWithIntersectionReadings.find((item) => item.x !== 0);

    if (firstReadingWithValueBeforeIndex?.x) {
      return firstReadingWithValueBeforeIndex.x;
    }

    return 0;
  }

  return { getDailyReadings, getAverage, formatReadings, getPreviousReading };
};
