import { useRegisterDeviceStore } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { useFlags } from "../../../Context/FlagsProvider";
import { useExtractURLSearch } from "../../../hooks/useExtractURLSearch";
import { usePrivileges } from "../../../hooks/usePrivilleges";
import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import { GetDevicesActivatedLastReadingsResponse } from "../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../store/details-device-store";
import { useDeviceListStore } from "../store/device-list-store";

export const useDeviceListCard = () => {
  const { getDeviceInfo } = useDeviceListService();
  const { hasPrivileges, hasBetaPrivileges } = useFlags();
  const { actions: detailsDeviceActions } = useDetailsDeviceStore();
  const { actions: registerDeviceActions } = useRegisterDeviceStore();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const { setParams } = useExtractURLSearch();
  const { actions: deviceListActions } = useDeviceListStore();
  const { features } = usePrivileges();

  function onAddressClick(lat: number, lng: number) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  }

  function onDetailsClick(item: GetDevicesActivatedLastReadingsResponse) {
    detailsDeviceActions.setDevice(item);
    detailsDeviceActions.setModalIsOpen(true);

    setParams({
      q: String(item.device_id),
    });
  }

  async function onEditClick(item: GetDevicesActivatedLastReadingsResponse) {
    deviceListActions.setModalType("edit");

    const response = await getDeviceInfo({ device_id: item.device_id });

    registerDeviceActions.setDeviceId(response.device_id);
    registerDeviceActions.setDeviceName(item?.channels[0]?.name || "");
    registerDeviceActions.setRemoteId(item?.channels[0]?.remote_id || "");
    registerDeviceActions.setDeviceMeasurementCategory(
      item.meta.device_measurement_category
    );
    registerDeviceActions.setAddressSelectedInfo({
      address:
        response.gps_location.address ||
        response.gps_location.display_address ||
        "",
      lat: response.gps_location.lat,
      lng: response.gps_location.long,
      cep: response.gps_location.zipcode,
      country: response.gps_location.country || "",
      city: response.gps_location.city || "",
      state: response.gps_location.state || "",
    });
  }

  function onRegisterDeviceClick() {
    deviceListActions.setModalType("register");
  }

  function onClickDecomission(item: GetDevicesActivatedLastReadingsResponse) {
    deviceListActions.setDeviceId(item.device_id);
    deviceListActions.setModalDecomissionIsOpen(true);
  }

  return {
    features,
    isMobile,
    onAddressClick,
    onDetailsClick,
    onEditClick,
    hasPrivileges,
    hasBetaPrivileges,
    onRegisterDeviceClick,
    onClickDecomission,
  };
};
