import { GLBigNumberCard, GLBox } from "@group-link-one/grouplink-components";

import { DeviceOnlyMap } from "../DeviceOnlyMap/DeviceOnlyMap";
import { GraphConsumptionHistory } from "../Graphs/ConsumptionHistory/ConsumptionHistory";
import { GraphDailyConsumption } from "../Graphs/DailyConsumption/DailyConsumption";
import {
  BigNumbersContainer,
  ChartsContainer,
} from "./DeviceDetailsContentStyle";
import { useDeviceDetailsContent } from "./useDeviceDetailsContent";

export function DeviceDetailsContent() {
  const { bigNumbers, isLoading, userUseCase } = useDeviceDetailsContent();

  return (
    <GLBox width="100%" style={{ marginTop: 50 }} gap={50} direction="column">
      <BigNumbersContainer>
        {bigNumbers.map((bigNumber) => (
          <GLBigNumberCard
            key={bigNumber.title}
            bigNumber={bigNumber}
            animation="fade-in-down"
            transition={{
              delay: 0.4,
              duration: 1.4,
            }}
            isLoading={isLoading}
          />
        ))}
      </BigNumbersContainer>

      {userUseCase !== "util_light" && (
        <ChartsContainer
          direction="row"
          gap={20}
          animation="fade-in-down"
          transition={{
            delay: 0.5,
            duration: 1.4,
          }}
        >
          <GraphConsumptionHistory />
          <GraphDailyConsumption />
        </ChartsContainer>
      )}

      <DeviceOnlyMap />
    </GLBox>
  );
}
