import { useAuth } from "@group-link-one/gl-auth";
import { useEventListStore, useGLModalLogout } from "@group-link-one/grouplink-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { useFireStore } from "../../Context/FireStoreProvider";

export const useAuthServices = () => {
  const { handleOpenModalLogout } = useGLModalLogout();
  const { logOut, accessToken } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { actions: eventListActions } =
    useEventListStore();

  const { resetAllUsersStoraged } = useFireStore();

  async function handleLogout(accessTokenParam?: string) {
    localStorage.removeItem("isImpersonated");

    try {
      if (!accessTokenParam) return;
      await logOut("");

      resetAllUsersStoraged();
      queryClient.clear();
      eventListActions.resetAll();
      navigate("/");
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          handleOpenModalLogout();
        }
      }
    }
  }

  const { mutateAsync: logoutFn } = useMutation<
    any,
    any,
    { session_id?: string }
  >({
    mutationFn: () => handleLogout(accessToken),
  });

  return {
    logOut: logoutFn,
  };
};
