import "react-day-picker/style.css";

import { AuthProvider, createStore, persistedReducer, persistStore } from "@group-link-one/gl-auth";
import { SmartRetailTheme } from "@group-link-one/grouplink-components";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { env } from "./env";
import { queryClient } from "./Services/queryClient";
import { analytics } from "./Services/remoteConfig";

analytics;

const store = createStore(persistedReducer(`@utilities-${env.NODE_ENV}`));
const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider baseURL={env.REACT_APP_API_URL}>
          <BrowserRouter>
            <SmartRetailTheme />
            <App />
          </BrowserRouter>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </QueryClientProvider>
);
