import {
  DataGroup,
  GL_COLORS,
  GL_GLStation,
  GLTransferListSideOptions,
  useGroupsDevicesStore,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import {
  GetDevicesActivatedLastReadingsResponse
} from "../../../Services/deviceListService/useDeviceListService.types";

const IPP_DEVICES = 50;
const IPP_DEVICES_IN_GROUP = 200;

interface GetDevicesInGroupParams {
  devicesFilteredData?: GetDevicesActivatedLastReadingsResponse[];
  text?: string;
  search?: string;
  getFromCache?: boolean;
  nextPageToken?: string;
  devicesFetched?: GetDevicesActivatedLastReadingsResponse[];
}

interface DevicesAvailablesCachedParams {
  devicesFiltereds: GetDevicesActivatedLastReadingsResponse[];
  hasMore: boolean;
  nextPageToken: string | undefined;
}

export const useGroupDevicesTransferList = () => {
  const [devicesAvailables, setDevicesAvailables] = useState<DataGroup[]>(
    []
  );
  const [devicesAvailablesData, setDevicesAvailablesData] = useState<
    GetDevicesActivatedLastReadingsResponse[]
  >([]);

  const [devicesAddeds, setDevicesAddeds] = useState<DataGroup[]>([]);
  const [devicesAddedsData, setDevicesAddedsData] = useState<
    number[]
  >([]);

  const [availableDevicesSearchIsCorrect, setAvailableDevicesSearchIsCorrect] = useState(true);
  const [devicesInGroupSearchIsCorrect, setDevicesInGroupSearchIsCorrect] = useState(true);

  const { t } = useI18n();

  const { getDevicesActivatedLastReadings } = useDeviceListService()

  const { state: groupDevicesState, actions: groupDevicesActions } =
    useGroupsDevicesStore();

  const isEditing = false;
  const queryClient = useQueryClient();

  const valueTosearchDeviceIDAvailablesDevices = (search: string) => {

    if (search.length >= 0 &&
      search.length < 10
    ) return undefined;

    return isNaN(Number(search))
      ? undefined
      : [Number(search)];
  }

  const leftSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: "users-availables-transfer-list",
          count: undefined,
          id: 1,
          name: t("groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne"),
          onClick: () => { },
        },
      ],
      color: GL_COLORS.ACCENT_COLOR,
      listName: t("groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne"),
      onScrollCallback: async () => {
        getMoreDevicesAvailables();
      },
    };
  }, [
    groupDevicesState.devices_ids,
    groupDevicesState.isFetchingMoreAvailableDevices,
    groupDevicesState.nextPageTokenAvailableDevices,
  ]);

  const rightSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: "users-in-group-transfer-list",
          count: undefined,
          id: 1,
          name: t("groupsDevices.addDevicesModal.transferList.rightSide.tabs.tabOne"),
          onClick: () => { },
        },
      ],
      color: GL_COLORS.LIGHT_GREEN,
      listName: t("groupsDevices.addDevicesModal.transferList.rightSide.tabs.tabOne"),
      onScrollCallback: async () => { },
    };
  }, [
    groupDevicesState.isFetchingMoreDevicesInGroup,
    groupDevicesState.nextPageTokenDevicesInGroup,
  ]);

  async function getDevicesAvailables({
    search,
    nextPageToken = undefined,
    devicesAlreadyFeched,
    getFromCache
  }: {
    search?: string;
    nextPageToken?: string;
    devicesAlreadyFeched?: GetDevicesActivatedLastReadingsResponse[];
    getFromCache?: boolean;
  }) {

    const queryKey = ["get-devices-availables"]

    if (search) {
      queryKey.push(search)
    }

    const devicesWithoutSearchCached: DevicesAvailablesCachedParams | undefined = queryClient.getQueryData(queryKey);

    if (getFromCache && devicesWithoutSearchCached && !search) {

      const devicesFormatted: DataGroup[] =
          formatDevicesAvailables(devicesWithoutSearchCached.devicesFiltereds);

      setDevicesAvailablesData(devicesWithoutSearchCached.devicesFiltereds);
      setDevicesAvailables(devicesFormatted);

      return {
        devicesFiltereds: [],
        hasMore: devicesWithoutSearchCached.hasMore,
        nextPageToken: devicesWithoutSearchCached.nextPageToken,
      };
    }

    return await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

        const availableDevices = await getDevicesActivatedLastReadings({
          next_page_token: nextPageToken,
          ipp: IPP_DEVICES,
          device_id: valueTosearchDeviceIDAvailablesDevices(search || ""),
          group_ids: []
        });

        groupDevicesActions.setIsFetchingMoreAvailableDevices(false);

        if (!search && availableDevices.has_more && availableDevices.next_page_token) {
          groupDevicesActions.setNextPageTokenAvailableDevices(
            availableDevices.next_page_token
          );
        }

        const devicesFiltereds = availableDevices.rows.filter(
          (device) =>
            groupDevicesState.devices_ids.indexOf(device.device_id) === -1 &&
            device.groups.length === 0
        );

        const devicesFormatted: DataGroup[] =
          formatDevicesAvailables(devicesFiltereds);

        setDevicesAvailables(devicesFormatted);

        if (!search) {
          setDevicesAvailablesData(devicesFiltereds);
        }

        return {
          devicesFiltereds: devicesAlreadyFeched ? devicesAlreadyFeched.concat(devicesFiltereds) : devicesFiltereds,
          hasMore: availableDevices.has_more,
          nextPageToken: availableDevices.next_page_token,
        };
      },
    });
  }

  async function getDevicesInGroup({
    devicesFilteredData,
    devicesFetched,
    text,
    search
  }: GetDevicesInGroupParams) {

    if (!groupDevicesState.groupsDevicesCardInfo.id) return;

    const queryKey = ["get-devices-in-group", groupDevicesState.groupsDevicesCardInfo.id]

    if (search) {
      queryKey.push(search)
    }

    const devicesInGroup = await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        const response = await getDevicesActivatedLastReadings({
          ipp: IPP_DEVICES_IN_GROUP,
          next_page_token: undefined,
          object_readings: true,
          all_devices: true,
          device_id: valueTosearchDeviceIDAvailablesDevices(search || ""),
          group_ids: [Number(groupDevicesState.groupsDevicesCardInfo.id)],
        })

        if (!search && response.has_more && response.next_page_token) {
          groupDevicesActions.setNextPageTokenDevicesInGroup(response.next_page_token)
        }

        return devicesFetched ? devicesFetched.concat(response.rows) : response.rows;
      }
    })

    const devicesFormatted: DataGroup[] = devicesInGroup.map(
      (device) => {
        return {
          id: String(device.device_id),
          title: String(device.channels[0].name),
          body: `ID: ${device.device_id}`,
          icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
        };
      }
    );

    const devicesAddedsIDs = devicesInGroup.map(
      (device) => device.device_id
    );

    setDevicesAddeds(devicesFormatted);

    if (devicesFilteredData) {
      const devicesAvailablesFiltered = devicesFilteredData.filter(
        (device) => devicesAddedsIDs.indexOf(device.device_id) === -1
      );

      setDevicesAvailablesData(devicesAvailablesFiltered);
      setDevicesAvailables(
        formatDevicesAvailables(devicesAvailablesFiltered)
      );
    }

    if (!text) {
      setDevicesAddedsData(devicesAddedsIDs);

      groupDevicesActions.setGroupsDeviceCardInfo({
        ...groupDevicesState.groupsDevicesCardInfo,
        devices_ids: devicesAddedsIDs,
      })
    }

  }

  function formatDevicesAvailables(
    currentDevicesAvailables: GetDevicesActivatedLastReadingsResponse[]
  ): DataGroup[] {
    if (!currentDevicesAvailables) return [];

    const devicesFormatted: DataGroup[] = currentDevicesAvailables?.map(
      (device) => {
        return {
          id: String(device.device_id),
          title: device.channels[0].name,
          body: `ID: ${device.device_id}`,
          icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
        };
      }
    );

    return devicesFormatted;
  }

  async function getMoreDevicesAvailables() {
    if (
      groupDevicesState.isFetchingMoreAvailableDevices ||
      !groupDevicesState.nextPageTokenAvailableDevices
    )
      return;

    groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

    const response = await getDevicesActivatedLastReadings({
      next_page_token: groupDevicesState.nextPageTokenAvailableDevices,
      ipp: IPP_DEVICES,
      group_ids: []
      // device_id: searchDeviceIDAvailablesDevices,
    });

    setDevicesAvailablesData([...devicesAvailablesData, ...response.rows]);

    groupDevicesActions.setIsFetchingMoreAvailableDevices(false);
    groupDevicesActions.setNextPageTokenAvailableDevices(response.next_page_token);

    const devicesAvailablesCached: DevicesAvailablesCachedParams | undefined =
      queryClient.getQueryData(["get-devices-availables"]);

    if (!devicesAvailablesCached) return;

    const {
      devicesFiltereds: currentDevicesAvailables,
    } = devicesAvailablesCached;

    const newDevicesAvailables = response.rows.filter(
      (device) => groupDevicesState.devices_ids.map(Number).indexOf(device.device_id) === -1 &&
        device.groups.length === 0
    );

    if (currentDevicesAvailables) {
      const currentDevicesAvailablesWihoutAddeds =
        currentDevicesAvailables.filter(
          (device) =>
            groupDevicesState.devices_ids.map(Number).indexOf(device.device_id) === -1
        );

      const newDevicesAvailablesFormatted: DataGroup[] =
        formatDevicesAvailables(
          currentDevicesAvailablesWihoutAddeds.concat(newDevicesAvailables)
        );

      setDevicesAvailables(newDevicesAvailablesFormatted);

      const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
        devicesFiltereds: currentDevicesAvailablesWihoutAddeds.concat(newDevicesAvailables),
        hasMore: response.has_more,
        nextPageToken: response.next_page_token,
      }

      queryClient.setQueryData(
        ["get-devices-availables"],
        newCachedDevicesAvailables
      );
    }
  }

  async function getMoreDevicesInGroup({
    isFetchingMoreDevicesInGroup,
    nextPageTokenDevicesInGroup
  }: {
    isFetchingMoreDevicesInGroup?: boolean;
    nextPageTokenDevicesInGroup?: string;
  }) {
    if (
      isFetchingMoreDevicesInGroup ||
      !nextPageTokenDevicesInGroup
    )
      return;

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(true);

    const response = await getDevicesActivatedLastReadings({
      ipp: IPP_DEVICES_IN_GROUP,
      next_page_token: nextPageTokenDevicesInGroup,
      object_readings: true,
      all_devices: true,
      group_ids: [Number(groupDevicesState.groupsDevicesCardInfo.id)],
    })

    setDevicesAddedsData([...devicesAddedsData, ...response.rows.map((device) => device.device_id)]);

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(false);
    groupDevicesActions.setNextPageTokenDevicesInGroup(response.next_page_token);

    const devicesInGroupCached: GetDevicesActivatedLastReadingsResponse[] | undefined =
      queryClient.getQueryData(["get-devices-in-group", groupDevicesState.groupsDevicesCardInfo.id]);

    if (!devicesInGroupCached) return;

    const newDevicesAvailablesFormatted: DataGroup[] =
      formatDevicesAvailables(
        devicesInGroupCached.concat(response.rows)
      );

    setDevicesAddeds(newDevicesAvailablesFormatted);

    queryClient.setQueryData(
      ["get-devices-in-group", groupDevicesState.groupsDevicesCardInfo.id],
      devicesInGroupCached.concat(response.rows)
    );
  }

  function onTransferItem(items: DataGroup[], type: "left" | "right") {
    const queryKeyDevicesInGroup = ["get-devices-in-group", groupDevicesState.groupsDevicesCardInfo.id];
    const queryKeyDevicesAvailables = ["get-devices-availables"];

    const devicesAddedsCached: GetDevicesActivatedLastReadingsResponse[] | undefined = queryClient.getQueryData(
      queryKeyDevicesInGroup
    );

    const devicesAvailablesObjectCached:  DevicesAvailablesCachedParams | undefined = queryClient.getQueryData(
      queryKeyDevicesAvailables
    );

    const {
      devicesFiltereds: devicesAvailablesCached,
      hasMore,
      nextPageToken
    } = devicesAvailablesObjectCached || {};

    if (!devicesAddedsCached || !devicesAvailablesCached) return;

    if (type === "right") {
      if (!devicesAddedsCached) return

      const devicesThatWillBeNotRemoved = devicesAddedsCached.filter(
        (device) => items.map((item) => Number(item.id)).indexOf(device.device_id) !== -1
      );

      const devicesThatWillBeToAddeds = devicesAddedsCached.filter(
        (device) => items.map((item) => Number(item.id)).indexOf(device.device_id) === -1
      );

      queryClient.setQueryData(
        queryKeyDevicesInGroup,
        devicesThatWillBeNotRemoved
      );

      if (devicesThatWillBeToAddeds.length > 0 && devicesAvailablesCached) {
        const allDevicesAvailales = [...devicesAvailablesCached, ...devicesThatWillBeToAddeds]

        const allDevicesAvailablesMap = new Map(
          allDevicesAvailales.map((device) => [device.device_id, device])
        )

        const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
          devicesFiltereds: Array.from(allDevicesAvailablesMap.values()),
          hasMore: Boolean(hasMore),
          nextPageToken,
        }

        queryClient.setQueryData(
          queryKeyDevicesAvailables,
          newCachedDevicesAvailables
        );
      }

      const devicesIds = items.map((device) => Number(device.id));

      if (groupDevicesState.devicesInGroupSearch.length === 0) {
        groupDevicesActions.setDevicesIds(devicesIds);

        const devicesAddedsDataFormatted: number[] = items.map(
          (device) => Number(device.id)
        );

        setDevicesAddedsData(devicesAddedsDataFormatted);
      } else {
        const allDevicesID = Array.from(new Set(devicesAddedsData.concat(devicesIds)))
        setDevicesAddedsData(allDevicesID);
      }

      setDevicesAddeds(items);
    }

    if (type === "left") {
      setDevicesAvailables(items);

      const currentDevicesSorted = items
        .map((item) => {
          return devicesAvailablesCached.find(
            (device) => device.device_id === Number(item.id)
          );
        })
        .filter((item) => item);

      const devicesThatWillBeToAddeds = devicesAvailablesCached.filter(
        (device) => currentDevicesSorted.map((item) => item?.device_id).indexOf(device.device_id) === -1
      );

      if (devicesThatWillBeToAddeds.length > 0 && devicesAddedsCached) {
        const allDevicesAddeds = [...devicesAddedsCached, ...devicesThatWillBeToAddeds]

        const allDevicesAddedsMap = new Map(
          allDevicesAddeds.map((device) => [device.device_id, device])
        )

        queryClient.setQueryData(
          queryKeyDevicesInGroup,
          Array.from(allDevicesAddedsMap.values())
        );
      }

      const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
        devicesFiltereds: currentDevicesSorted.map((item) => item as GetDevicesActivatedLastReadingsResponse),
        hasMore: Boolean(hasMore),
        nextPageToken,
      }



      queryClient.setQueryData(
        ["get-devices-availables"],
        newCachedDevicesAvailables
      );

      if (groupDevicesState.devicesInGroupSearch.length > 0) {
        const lastItemFromAvailables = items.slice(-1)[0];

        const newDevicesAddedsData = devicesAddedsData.filter(
          (device) => device !== Number(lastItemFromAvailables.id)
        );

        groupDevicesActions.setDevicesIds(
          newDevicesAddedsData.map((device) => device)
        );

        setDevicesAddedsData(newDevicesAddedsData);
      }
    }
  }

  function onSearch(value: string, side: "left" | "right") {
    if (side === "left") {
      groupDevicesActions.setAvailableDevicesSearch(value);

      if (value.length === 0 ||
        value.length >= 10
      ) {
        getDevicesAvailables({
          search: value,
          getFromCache: value.length === 0
        });
        setAvailableDevicesSearchIsCorrect(true);
      } else {
        setAvailableDevicesSearchIsCorrect(false);
      }
    }

    if (side === "right") {
      groupDevicesActions.setDevicesInGroupSearch(value);

      if (value.length === 0 ||
        value.length >= 10
      ) {
        getDevicesInGroup({
          search: value,
          getFromCache: value.length === 0
        });
        setDevicesInGroupSearchIsCorrect(true);
      } else {
        setDevicesInGroupSearchIsCorrect(false);
      }
    }
  }

  async function initOnOpenModal({
    devicesAlreadyFeched,
    nextPageTokenParam
  }: {
    nextPageTokenParam?: string,
    devicesAlreadyFeched?: GetDevicesActivatedLastReadingsResponse[];
  }) {

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(true);

    const { devicesFiltereds, hasMore, nextPageToken } = await getDevicesAvailables({
      nextPageToken: nextPageTokenParam,
      devicesAlreadyFeched
    });

    if (devicesFiltereds.length <= 7 && hasMore) {
      initOnOpenModal({
        devicesAlreadyFeched: devicesFiltereds,
        nextPageTokenParam: nextPageToken
      });
      return
    }

    setDevicesAddeds([]);

    await getDevicesInGroup({ devicesFilteredData: devicesFiltereds });

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(false);
  }

  useEffect(() => {
    if (groupDevicesState.openAddDevicesModal) {
      initOnOpenModal({})
    }

    if (!groupDevicesState.openAddDevicesModal) {
      setDevicesAddeds([]);
    }

    groupDevicesActions.setAvailableDevicesSearch("");
    groupDevicesActions.setDevicesInGroupSearch("");
  }, [groupDevicesState.openAddDevicesModal, isEditing]);

  useEffect(() => {
    groupDevicesActions.setDevicesIds(devicesAddedsData);
  }, [devicesAddedsData])

  useEffect(() => {
    getMoreDevicesInGroup({
      isFetchingMoreDevicesInGroup: groupDevicesState.isFetchingMoreDevicesInGroup,
      nextPageTokenDevicesInGroup: groupDevicesState.nextPageTokenDevicesInGroup
    });
  }, [
    groupDevicesState.nextPageTokenDevicesInGroup,
    groupDevicesState.isFetchingMoreDevicesInGroup
  ])

  return {
    t,
    availableDevicesSearchIsCorrect,
    devicesInGroupSearchIsCorrect,
    devicesAvailables,
    devicesAddeds,
    leftSideOptions,
    rightSideOptions,
    groupDevicesState,
    onTransferItem,
    onSearch,
  };
};
