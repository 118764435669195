import { useAuth } from "@group-link-one/gl-auth";
import {
  useEventListStore,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { useEffect, useMemo } from "react";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import {
  Application,
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
} from "../../../Services/deviceListService/useDeviceListService.types";
import { useEventListService } from "../../../Services/eventListService/useEventListService";
import { GetEventListResponse } from "../../../Services/eventListService/useEventListService.types";
import { useAllColumns, UseCase } from "./Columns/AllColumns";

export const useTableContent = () => {
  const { t } = useI18n();

  const { getDevicesActivatedLastReadings } = useDeviceListService();

  const { getEventList } = useEventListService();
  const { actions, state } = useEventListStore();
  const { addToast } = useToast();
  const { user } = useAuth();

  const queryClient = useQueryClient();
  const columns = useAllColumns();

  const fromDate = useMemo(() => {
    return format(
      new Date(state.range.from || new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    );
  }, [state.range.from]);

  const toDate = useMemo(() => {
    return format(
      new Date(state.range.to || new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    );
  }, [state.range.to]);

  const searchDeviceID = useMemo(() => {
    if (state.searchDeviceId.length === 0) return undefined;

    return isNaN(Number(state.searchDeviceId))
      ? undefined
      : [Number(state.searchDeviceId)];
  }, [state.searchDeviceId]);

  const {
    data: events,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["get-event-list", fromDate, toDate, state.deviceSelected],
    queryFn: async () => {
      if (!state.range.from || !state.range.to) return [];

      if (state.deviceSelected) {
        try {
          const response = await getEventList({
            from: format(
              new Date(state.range.from || new Date()),
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
            ),
            until: format(
              new Date(state.range.to || new Date()),
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
            ),
            next_page_token: undefined,
            device_id: [Number(state.deviceSelected)],
            application: getApplicationByUseCase(user?.use_case as UseCase),
            ipp: 40,
          });

          actions.setNextPageToken(response.next_page_token || "");

          if (response.rows.length === 0) {
            actions.setEmptystate(
              t("eventList.list.emptyState.title2"),
              t("eventList.list.emptyState.description2"),
            );

            addToast({
              title: `${t("attention")}!`,
              message: t("eventList.list.emptyState.description2"),
              type: "info",
            });

            return;
          }

          actions.setError(false);

          return response.rows;
        } catch (error: unknown) {
          if (error instanceof AxiosError) {
            if (
              error.response?.data.message[0] ===
                "from must be a valid ISO 8601 date string" ||
              error.response?.data.message[1] ===
                "until must be a valid ISO 8601 date string"
            ) {
              addToast({
                title: t("eventList.errors.title"),
                message: t("eventList.errors.message"),
                type: "error",
              });
              actions.setError(true);
              return;
            }

            if (error.response?.data.statusCode === 500) {
              addToast({
                title: t("eventList.errors.title"),
                message: t("eventList.errors.message"),
                type: "error",
              });
              actions.setError(true);
              return;
            }

            actions.setError(true);
            return;
          }
          actions.setError(true);
        }
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const previousData = queryClient.getQueryData(["get-event-list"]) as any;

  function getApplicationByUseCase(use_case: UseCase): Application {
    const applicationMap: Record<UseCase, Application> = {
      util_kwh: "GLUtilitiesEnergy",
      util_water: "GLUtilitiesWater",
      util_light: "GLUtilitiesLight",
    };

    return applicationMap[use_case] || "GLUtilitiesWater";
  }

  async function getMoreEvents() {
    const devicesIDFormatted = state.devices_id_to_request?.map((deviceid) =>
      Number(deviceid),
    );

    if (!state.next_page_token) return [];

    actions.setIsFetchingMoreEvents(true);

    const response = await getEventList({
      from: fromDate,
      until: toDate,
      next_page_token: state.next_page_token,
      device_id: devicesIDFormatted,
      application: getApplicationByUseCase(user?.use_case as UseCase),
      ipp: 40,
    });

    actions.setIsFetchingMoreEvents(false);

    actions.setNextPageToken(response.next_page_token || "");

    if (response.rows.length === 0) {
      actions.setEmptystate(
        t("eventList.list.emptyState.title2"),
        t("eventList.list.emptyState.description2"),
      );

      addToast({
        title: `${t("attention")}!`,
        message: t("eventList.list.emptyState.description2"),
        type: "info",
      });

      return;
    }

    actions.setError(false);

    return response.rows;
  }

  const { data: listDeviceIDs, isLoading: listDeviceIDsIsLoading } = useQuery({
    queryKey: ["get-devices-id-event-list", searchDeviceID],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const options: GetDevicesActivatedLastReadingsParams = {
        all_devices: true,
        // skip_value_lookup: true,
        next_page_token: undefined,
        device_id: searchDeviceID,
      };

      const response = await getDevicesActivatedLastReadings(options);

      if (response.has_more && response.next_page_token) {
        actions.setNextPageTokenDevices(response.next_page_token);
      }

      return response.rows;
    },
  });

  async function incrementDevicesID() {
    if (state.searchDeviceId.length > 0 || !state.next_page_token_devices)
      return;

    const currentDevicesIDCached: GetDevicesActivatedLastReadingsResponse[] =
      (await queryClient.getQueryData([
        "get-devices-id-event-list",
        searchDeviceID,
      ])) || [];

    const options: GetDevicesActivatedLastReadingsParams = {
      all_devices: true,
      // skip_value_lookup: true,
      next_page_token: state.next_page_token_devices,
    };

    actions.setIsFetchingMoreDevices(true);

    const newDevices = await getDevicesActivatedLastReadings(options);

    if (newDevices.has_more && newDevices.next_page_token) {
      actions.setNextPageTokenDevices(newDevices.next_page_token);
    }

    actions.setIsFetchingMoreDevices(false);

    const newDevicesID = currentDevicesIDCached
      .concat(newDevices.rows)
      .map((device) => device.device_id);
    const uniquesDevicesID = Array.from(new Set(newDevicesID));

    queryClient.setQueryData(
      ["get-devices-id-event-list", searchDeviceID],
      currentDevicesIDCached.concat(newDevices.rows),
    );

    actions.setDevicesId(uniquesDevicesID);
  }

  const isAtBottom = ({ currentTarget }: React.UIEvent<HTMLElement>) => {
    const finalScroll = currentTarget.scrollHeight - currentTarget.clientHeight;

    return (
      finalScroll - currentTarget.scrollTop < 100 &&
      finalScroll - currentTarget.scrollTop > -100
    );
  };

  const handleScroll = async (event: React.UIEvent<HTMLElement>) => {
    if (!isAtBottom(event) || state.isFetchingMoreEvents) return;

    const moreEvents: GetEventListResponse[] | undefined =
      await getMoreEvents();

    if (!moreEvents || moreEvents.length === 0) return;

    const currentData: GetEventListResponse[] | undefined =
      queryClient.getQueryData([
        "get-event-list",
        fromDate,
        toDate,
        state.devices_id_to_request,
      ]);

    if (!currentData) return;

    queryClient.setQueryData(
      ["get-event-list", fromDate, toDate, state.devices_id_to_request],
      currentData.concat(moreEvents),
    );

    // setDevicesIDAvailable(currentData.concat(moreEvents));
  };

  useEffect(() => {
    actions.setFetchMoreDevices(() => {
      if (state.isFetchingMoreDevices) return;
      incrementDevicesID();
    });
  }, [
    state.isFetchingMoreDevices,
    state.next_page_token_devices,
    state.searchDeviceId,
  ]);

  useEffect(() => {
    actions.resetAll();
  }, []);

  useEffect(() => {
    if (listDeviceIDs === undefined) return;
    const devicesID = listDeviceIDs.map((device) => device.device_id);
    const uniquesDevicesID = Array.from(new Set(devicesID));

    actions.setDevicesId(uniquesDevicesID);
  }, [listDeviceIDs]);

  useEffect(() => {
    actions.setDeviceIsLoading(listDeviceIDsIsLoading);
  }, [listDeviceIDsIsLoading]);

  return {
    events,
    handleScroll,
    // devices_id,
    isLoading,
    previousData,
    // deviceLoading,
    actions,
    state,
    refetch,
    columns,
  };
};
