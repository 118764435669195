import {
  GL_COLORS,
  GLBox,
  GLModal,
  GLSelectMultiple,
  GLTypography,
} from "@group-link-one/grouplink-components";
import React from "react";

import { alertsMap } from "../../../DeviceList/ModalDeviceDetails/TitleContent/useDeviceDetailsTitleContent";
import { useModalFilter } from "./useModalFilter";

export function ModalFilter() {
  const { t, healthCheckActions, healthCheckState } = useModalFilter();

  return (
    <GLModal
      open={healthCheckState.modalFilterIsOpen}
      type="create"
      title={t("deviceList.search.filterOptions.title")}
      position="right"
      description={t("deviceList.search.filterOptions.description")}
      hasButtonClose={false}
      hasOutsideClick={true}
      modalContentStyle={{
        justifyContent: "flex-start",
      }}
      content={
        <GLBox direction="column" gap={15}>
          <GLBox
            height={2}
            width="100%"
            borderRadius={8}
            style={{
              background: GL_COLORS.BORDER_COLOR,
            }}
          />

          <GLBox direction="column">
            <GLTypography text="Devices" />

            <GLSelectMultiple
              options={[
                { id: "all", text: "Todos" },
                {
                  id: "with-alert",
                  text: "Com alerta",
                  content: (
                    <GLSelectMultiple
                      hasInput={false}
                      canSelectMultiple={false}
                      options={Object.entries(alertsMap).map(
                        ([key, value]) => ({
                          id: key,
                          text: value,
                        })
                      )}
                      optionsSelected={
                        healthCheckState.alertTypesSelected || []
                      }
                      boxOptions={{
                        width: 200,
                        position: "bottom-right",
                      }}
                      onSelectedOptionsChange={(selectedOptions) => {
                        if (
                          selectedOptions.length === 0 &&
                          (!healthCheckState?.alertTypesSelected ||
                            healthCheckState?.alertTypesSelected?.length === 0)
                        )
                          return;

                        healthCheckActions.setAlertTypesSelected(
                          selectedOptions
                        );
                        healthCheckActions.setAlertFilterValue(selectedOptions);
                      }}
                    />
                  ),
                },
                { id: "without-alert", text: "Sem alerta" },
              ]}
              optionsSelected={healthCheckState.alertFilterValue || []}
              rootOptions={{
                style: {
                  minWidth: "100%",
                },
              }}
              inputOptions={{
                placeholder: "Filtrar por alerta",
              }}
              boxOptions={{
                width: "100%",
                position: "bottom-right",
              }}
              canSelectMultiple={false}
              hasCheckbox={false}
              isAsynchronous
              onSelectedOptionsChange={(selectedOptions) => {
                if (selectedOptions.length === 0) {
                  healthCheckActions.setAlertFilterValue([
                    { id: "all", text: "Todos" },
                  ]);
                  return;
                }

                const isAllOrWithoutAlert = selectedOptions.some(
                  (option) =>
                    option.id === "all" || option.id === "without-alert"
                );
                if (!isAllOrWithoutAlert) return;

                healthCheckActions.setAlertTypesSelected([]);
                healthCheckActions.setAlertFilterValue(selectedOptions);
              }}
            />

            {/*<GLSelectMultiple
              options={[
                { id: "all", text: "Todos" },
                {
                  id: "with-alert",
                  text: "Com Leitura",
                  content: (
                    <GLSelectMultiple
                      hasInput={false}
                      canSelectMultiple={false}
                      options={Object.entries(alertsMap).map(
                        ([key, value]) => ({
                          id: key,
                          text: value,
                        })
                      )}
                      optionsSelected={
                        healthCheckState.alertTypesSelected || []
                      }
                      boxOptions={{
                        width: 200,
                        position: "bottom-right",
                      }}
                      onSelectedOptionsChange={(selectedOptions) => {
                        if (
                          selectedOptions.length === 0 &&
                          (!healthCheckState?.alertTypesSelected ||
                            healthCheckState?.alertTypesSelected?.length === 0)
                        )
                          return;

                        healthCheckActions.setAlertTypesSelected(
                          selectedOptions
                        );
                        healthCheckActions.setAlertFilterValue(selectedOptions);
                      }}
                    />
                  ),
                },
                { id: "without-alert", text: "Sem leitura" },
              ]}
              optionsSelected={healthCheckState.alertFilterValue || []}
              rootOptions={{
                style: {
                  minWidth: "100%",
                },
              }}
              inputOptions={{
                placeholder: "Filtrar por alerta",
              }}
              boxOptions={{
                width: "100%",
                position: "bottom-right",
              }}
              canSelectMultiple={false}
              hasCheckbox={false}
              isAsynchronous
              onSelectedOptionsChange={(selectedOptions) => {
                if (selectedOptions.length === 0) {
                  healthCheckActions.setAlertFilterValue([
                    { id: "all", text: "Todos" },
                  ]);
                  return;
                }

                const isAllOrWithoutAlert = selectedOptions.some(
                  (option) =>
                    option.id === "all" || option.id === "without-alert"
                );
                if (!isAllOrWithoutAlert) return;

                healthCheckActions.setAlertTypesSelected([]);
                healthCheckActions.setAlertFilterValue(selectedOptions);
              }}
            /> */}
          </GLBox>
          {/*<GLRelativeFilterTime {...relativeFilterTimeOptions} />*/}
        </GLBox>
      }
      onClose={() => {
        healthCheckActions.setModalFilterIsOpen(false);
      }}
    />
  );
}
